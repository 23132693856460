// .rpv-default-layout__sidebar-headers{
//     display: none;
// }


// .rpv-default-layout__toolbar{
//     display: none !important;
// }

// .rpv-default-layout__body {
//     padding-top: 0px !important;
// }

// .rpv-default-layout__container{
//     border: none !important
// }

// .rpv-default-layout__sidebar--ltr {
//     border: none !important
// }


/** updated styles----------------------------------- **/

.rpv-default-layout__sidebar-headers{
    display: none;
}

.rpv-default-layout__body {
    padding-top: 0px !important;
}

.rpv-default-layout__container{
    border: none !important
}

.rpv-default-layout__sidebar--ltr {
    border: none !important
}

.rpv-toolbar__center{
    justify-content: end !important
}

.rpv-toolbar__left > :nth-child(1){
    display: none !important;
}

.rpv-toolbar__right > :nth-child(1){
    display: none !important;
}

.rpv-toolbar__right > :nth-child(3){
    display: none !important;
}

.rpv-toolbar__right > :nth-child(4){
    display: none !important;
}

.rpv-toolbar__right > :nth-child(5){
    display: none !important;
}

.rpv-toolbar__right > :nth-child(6){
    display: none !important;
}