@import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
@import "@blueprintjs/core/lib/css/blueprint.css";
@import "normalize.css";

.bp4-datepicker-month-select {
  min-width: 130px;
}

.bp4-datepicker-year-select {
  min-width: 70px;
  font-family: var(--font-family);
}

.calendar-picker {
  font-family: var(--font-family);
}

.bp4-input-action{
  top: 0;
  bottom: 0;
  margin: auto 0;
}

.bp4-control-group:not(.bp4-vertical) > :not(:last-child) {
  margin-right: 8px;
}

.bp4-input:focus, .bp4-input.bp4-active {
  box-shadow: none
}

.bp4-menu.bp4-daterangepicker-shortcuts {
  li {
    margin-bottom: 8px;
  }
}