.ui-table {
  padding-bottom: 0;
  
  table {
    border-collapse: collapse;
    width: 100%;

    td, th { 
      margin: 0;
      padding: 0;
    }

    thead {
      background-color: transparent;
    }

    tbody {
      tr:last-child {
        border-bottom: 0; 
      }
    }
  }

  &__dropdown {
    align-items: center;
    background-color: var(--color-N200);
    border-radius: 4px;
    color: var(--color-N800);
    display: flex;
    height: 36px;
    position: relative;
    transition: all 0.15s ease-in-out;
  
    select {
      align-items: center;
      appearance: none;
      background-color: transparent;
      border: 1px solid transparent;
      border-radius: 4px;
      color: var(--color-N800);
      cursor: pointer;
      display: inline-flex;
      font-family: var(--font-family);
      font-weight: 500;
      height: 100%;
      letter-spacing: -0.18px;
      outline: none;
      padding-left: 12px;
      padding-right: 24px;
      position: relative;
      z-index: 10;
    }
  
    i {
      position: absolute;
      right: 12px;
      z-index: -0;
    }
  
    &:hover {
      background-color: var(--color-N300);
    }
  
    &:active {
      background-color: var(--color-N400);
    }
  }

  /* Pagination */
  &__pagination {
    width: calc(100vw - 224px - 64px - 24px);
  }
}

