@import url('https://rsms.me/inter/inter.css');

@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'remixicon/fonts/remixicon.css';
@import 'react-phone-input-2/lib/style.css';

@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

*{
    font-family: 'Inter', sans-serif;
}

/* Root */
html {
    @media screen and (min-width: 1441px) {
      zoom: 97%;
    }
    @media screen and (max-width: 1440px) {
      zoom: 95%;
    }
    @media screen and (max-width: 1540px) {
      zoom: 93%;
    }
    @media screen and (max-width: 1280px) {
      zoom: 91%;
    }
  }

.back-drop{
    background: url("../assets/images/tempbgimage.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

.login-left-background-image{
    background: url("../assets/images/loginUI.png");
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
}

.styled-scroll::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 2px;
	background-color: #F5F5F5;
}

.styled-scroll::-webkit-scrollbar
{
	width: 5px;
  height:5px;
	background-color: #F5F5F5;
}

.styled-scroll::-webkit-scrollbar-thumb
{
	border-radius: 2px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #E2E8F0;
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}

@page {
  size: auto;
  margin: 10mm;
}

@media print {
  @page {
      margin-bottom: 0;
      margin-bottom: 28px;
  }
  body {
      padding-top: 32px;
      padding-bottom: 40px ;
      margin-bottom: 32px;
  }
}