@import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
@import "@blueprintjs/core/lib/css/blueprint.css";
@import "normalize.css";

.bp4-datepicker-month-select {
  min-width: 130px;
}

.bp4-datepicker-year-select {
  min-width: 70px;
  font-family: var(--font-family);
}

.bp4-datepicker-content{
  font-family: var(--font-family);
  background: #FFFFFF;
  box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  padding:5px
}

.calendar-picker {
  font-family: var(--font-family);
}

.bp4-icon-double-caret-vertical{
 right: 0 !important;
}


.bp4-input{
  font-family: var(--font-family);
  border: 1px solid #D1D5DB;
  /* shadow/sm */

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  height: 40px;
  color:'#1F2937'
}

.DayPicker-Body{
  padding: 8px 10px !important; 

}
 