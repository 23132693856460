.modal__footer {
  @apply bg-white;
  border-top: 1px solid #E5E7EB;
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.04);
  display: flex;
  flex: 1;
  justify-content: flex-end;
  padding-bottom: 20px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 20px;
  width: 100%;
}
